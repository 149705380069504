import React from "react"
import Layout from "../components/layout"
import MiscContent from "../layouts/MiscContent"
import { LayoutTitle } from "../styles/app.styles"
import HomeHero from "../layouts/HomeHero/home-hero.component"
import AppInfo from "../layouts/AppInfo/app-info.component"
import { graphql, useStaticQuery } from "gatsby"
import ExperienceSlider from "../layouts/ExperienceSlider/experience-slider.component"
import ImagesCollage from "../layouts/ImagesCollage/images-collage.component"
import CallToAction from "../layouts/CallToAction/call-to-action.component"
import ImageSlider from "../layouts/ImageSlider/image-slider.component"
import InfoVideo from "../layouts/InfoVideo/info-video.component"
import PricingPlans from "../layouts/PricingPlans/pricing-plans.component"
import CustomHero from "../layouts/CustomHero"
import BuiltForInvestors from "../layouts/BuiltForInvestors/built-for-investors.component"
import TransparencyTracking from "../layouts/TransparencyTracking"
import NextToFooter from "../layouts/NextToFooter/next-to-footer.component"
import WhatSetsApart from "../layouts/WhatSetsApart/what-sets-apart.component"
import BackedBy from "../layouts/BackedBy/backed-by.component"
import CostsAndFees from "../layouts/CostsAndFees/costs-and-fees.component"
import SubmitDeal from "../layouts/SubmitDeal/submit-deal.component"
import AboutCalculator from "../layouts/AboutCalculator/about-calculator.component"
import HowItWorks from "../layouts/HowItWorks/how-it-works.component"
import InvestorsSlider from "../layouts/InvestorsSlider/investors-slider.component"
import SecondaryHero from "../layouts/SecondaryHero"
import ExperiencesGrid from "../layouts/ExperiencesGrid/experiences-grid.component"
import DemoHero from "../layouts/DemoHero/demo-hero.component"
import ShortDemoHero from "../layouts/ShortDemoHero/short-demo-hero.component"
import TrustedBySlider from "../layouts/TrustedBySlider/trusted-by-slider.component"
import FaqHero from "../layouts/FaqHero/faq-hero.component"
import Faq from "../assets/faq.json"
import FaqContent from "../layouts/FaqContent/faq-content.component"
import { Helmet } from "react-helmet"
import CardsOurServices from "../layouts/CardsOurServices"
import PostProperty from "../layouts/PostProperty"
import TestimonialBanner from "../layouts/TestimonialBanner"

const Layouts = () => {
  const staticQuery = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "home-hero.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      user: file(relativePath: { eq: "photo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      deals: file(relativePath: { eq: "deals.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      dealTracker: file(relativePath: { eq: "deal-tracker.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      appImage: file(relativePath: { eq: "appImage.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      suiteImage: file(relativePath: { eq: "suiteImage.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      bgEmpora: file(relativePath: { eq: "bgEmpora.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      trusted1: file(relativePath: { eq: "trusted1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      trusted2: file(relativePath: { eq: "trusted2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      trusted3: file(relativePath: { eq: "trusted3.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      collage: file(relativePath: { eq: "collage1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      cta: file(relativePath: { eq: "tablet.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      emporaIcon: file(relativePath: { eq: "background-icon.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      imgSlider: file(relativePath: { eq: "imageSlider.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      imageVideo: file(relativePath: { eq: "productTour.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      playIcon: file(relativePath: { eq: "play.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      personHero: file(relativePath: { eq: "personHero.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      fireworks: file(relativePath: { eq: "fireworks.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      clock: file(relativePath: { eq: "clock.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      note: file(relativePath: { eq: "note.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      rocket: file(relativePath: { eq: "rocket.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      allDeals: file(relativePath: { eq: "allDeals.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      house: file(relativePath: { eq: "house.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      hand: file(relativePath: { eq: "hand.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      imgChatOne: file(relativePath: { eq: "imgChat1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      imgChatTwo: file(relativePath: { eq: "imgChat2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      partialLogo: file(relativePath: { eq: "partial-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      bgHouse: file(relativePath: { eq: "bgHouse.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      dealTracker2: file(relativePath: { eq: "deal-tracker-2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      dealInfo2: file(relativePath: { eq: "deal-info-2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      title1: file(relativePath: { eq: "title-1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      title2: file(relativePath: { eq: "title-2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      title3: file(relativePath: { eq: "title-3.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      sciLogo: file(relativePath: { eq: "sciLogo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      rootLogo: file(relativePath: { eq: "rootLogo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      capitalBLogo: file(relativePath: { eq: "capitalBLogo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      submitDeal: file(relativePath: { eq: "submit-deal.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      businessIcon: file(relativePath: { eq: "business.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      productIcon: file(relativePath: { eq: "product.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      phones: file(relativePath: { eq: "phones.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      calculatorHero: file(relativePath: { eq: "calculator-hero.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      getStartedHero: file(relativePath: { eq: "getStartedHero.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      trustedByBG: file(relativePath: { eq: "investors_bg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      googleLogo: file(relativePath: { eq: "google_logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      facebookLogo: file(relativePath: { eq: "facebook_logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }

      authorImage: file(relativePath: { eq: "avatar.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)
  return (
    <Layout>
      <Helmet>
        <title>Layouts - ONLY FOR DEV</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <HomeHero
        title="This is title built for real estate investors"
        comments={[
          {
            description: `“Empora 1 Title made it incredibly simple for me to start earning money online selling courses. It's a game changer!”`,
            authorName: "Nombre Apellido",
            authorOccupation: "Ocupacion",
          },
          {
            description: `“Empora 2 Title made it incredibly simple for me to start earning money online selling courses. It's a game changer!”`,
            authorName: "Nombre Apellido",
            authorOccupation: "Ocupacion",
          },
          {
            description: `“Empora 3 Title made it incredibly simple for me to start earning money online selling courses. It's a game changer!”`,
            authorName: "Nombre Apellido",
            authorOccupation: "Ocupacion",
          },
        ]}
        image={staticQuery.hero}
        deals={staticQuery.deals}
        dealTracker={staticQuery.dealTracker}
      />
      <LayoutTitle>MiscContent</LayoutTitle>
      <MiscContent content={`<p>Some content Here</p>`} />
      <LayoutTitle>AppInfo</LayoutTitle>
      <AppInfo
        appImage={staticQuery.appImage}
        title={"Save 30% in closing costs"}
        text={"This is digital-first title."}
        paragraph={
          "We’ve reimagined the title and closing process for real estate investors - building a digital-first experience that delivers the fastest, smoothest closings possible, simplifies the management of multiple deals."
        }
        suiteImage={staticQuery.suiteImage}
        logoImage={staticQuery.bgEmpora}
      />
      <LayoutTitle>ExperienceSlider</LayoutTitle>
      <ExperienceSlider
        title="A superior closing experience awaits"
        slides={[
          {
            title: "Communication&nbsp;👋",
            description: `At Empora you’’ll always have direct line to one of our title experts.
               We make sure you have seamless communication from the beginning to end on your deal.1`,
          },
          {
            title: "Technology ",
            description: `At Empora you’’ll always have direct line to one of our title experts.
               We make sure you have seamless communication from the beginning to end on your deal.2`,
          },
          {
            title: "Experts",
            description: `At Empora you’’ll always have direct line to one of our title experts.
               We make sure you have seamless communication from the beginning to end on your deal.3`,
          },
          {
            title: "Transparency",
            description: `At Empora you’’ll always have direct line to one of our title experts.
               We make sure you have seamless communication from the beginning to end on your deal.4`,
          },
          {
            title: "Scaling",
            description: `At Empora you’’ll always have direct line to one of our title experts.
               We make sure you have seamless communication from the beginning to end on your deal.5`,
          },
        ]}
      />
      <LayoutTitle>Images Collage</LayoutTitle>
      <ImagesCollage
        title={`Technology Backed By Title Experts`}
        description={`At Empora you’’ll always have direct line to one of our title experts.
           We make sure you have seamless communication from the beginning to end on your deal. We’ve got your back!`}
        trustedByLogos={[
          {
            name: "First American",
            img: staticQuery.trusted1,
          },
          {
            name: "First American",
            img: staticQuery.trusted2,
          },
          {
            name: "First American",
            img: staticQuery.trusted3,
          },
        ]}
        images={[
          {
            img: staticQuery.hero,
          },
          {
            img: staticQuery.hero,
          },
          {
            img: staticQuery.hero,
          },
          {
            img: staticQuery.hero,
          },
          {
            img: staticQuery.hero,
          },
          {
            img: staticQuery.hero,
          },
          {
            img: staticQuery.hero,
          },
          {
            img: staticQuery.hero,
          },
          {
            img: staticQuery.hero,
          },
          {
            img: staticQuery.hero,
          },
        ]}
      />
      <LayoutTitle>CallToAction</LayoutTitle>
      <CallToAction
        title={`A superior closing experience awaits`}
        description={`Our digital-first experience delivers the fastest, smoothest closings possible, 
        simplifies the management of multiple deals, and saves about $750 in closing costs on every deal.`}
        image={staticQuery.cta}
        iconPosition="right"
      />
      <LayoutTitle>ImagesSlides</LayoutTitle>
      <ImageSlider
        title={"Serious investors are scaling with Empora"}
        slides={[
          {
            paragraph:
              "I love that Empora lets me manage everything in one place. It's super helpful to be able to listen to voice samples, upload our own lists, and find quality people that can grow with our team.",
            name: "Michael Mahanay ",
            occupation: "VP and CMO at CTL",
            image: staticQuery.imgSlider,
            leftImage: staticQuery.imgSlider,
          },
          {
            paragraph:
              "I love that Empora lets me manage everything in one place. It's super helpful to be able to listen to voice samples, upload our own lists, and find quality people that can grow with our team.",
            name: "Michael Mahanay",
            occupation: "VP and CMO at CTL",
            image: staticQuery.imgSlider,
            leftImage: staticQuery.imgSlider,
          },
          {
            paragraph:
              "I love that Empora lets me manage everything in one place. It's super helpful to be able to listen to voice samples, upload our own lists, and find quality people that can grow with our team.",
            name: "Michael Mahanay",
            occupation: "VP and CMO at CTL",
            image: staticQuery.imgSlider,
            leftImage: staticQuery.imgSlider,
          },
          {
            paragraph:
              "I love that Empora lets me manage everything in one place. It's super helpful to be able to listen to voice samples, upload our own lists, and find quality people that can grow with our team.",
            name: "Michael Mahanay",
            occupation: "VP and CMO at CTL",
            image: staticQuery.imgSlider,
            leftImage: staticQuery.imgSlider,
          },
          {
            paragraph:
              "I love that Empora lets me manage everything in one place. It's super helpful to be able to listen to voice samples, upload our own lists, and find quality people that can grow with our team.",
            name: "Michael Mahanay",
            occupation: "VP and CMO at CTL",
            image: staticQuery.imgSlider,
            leftImage: staticQuery.imgSlider,
          },
        ]}
      />
      <LayoutTitle>Video</LayoutTitle>
      <InfoVideo
        videoId={"XdChXSXXPE4"}
        title={"Changing the way you do business"}
        videoImage={staticQuery.imageVideo}
        videoUrl={"https://www.youtube.com/embed/XdChXSXXPE4"}
      />
      <LayoutTitle>PricingPlans</LayoutTitle>
      <PricingPlans
        title={`Cut closing costs by up to 30%`}
        description={`We’ve eliminated fees that other title companies charge, saving our customers as much as 30% on closing costs—per deal.`}
        closingCosts={[
          {
            title: `Closing costs with others`,
            salePriceExample: "250,00",
            description: `Empora lets you close wherever and whenever it’s convenient for you. `,
            options: [
              {
                isWrong: true,
                text: "Something goes here about option",
              },
              {
                isWrong: true,
                text: "Something goes here about option",
              },
              {
                isWrong: true,
                text: "Something goes here about option",
              },
            ],
            tableItems: [
              {
                title: "Title insurance costs",
                buyerPrice: "1,188",
                sellerPrice: "0",
                tip: "Empora lets you close wherever and whenever it’s convenient for you.",
              },
              {
                title: "Closing fees",
                buyerPrice: "300",
                sellerPrice: "0",
              },
              {
                title: "Transfer fees",
                buyerPrice: "380",
                sellerPrice: "0",
              },
            ],
          },

          {
            title: `Closing costs with`,
            hasLogo: true,
            bgColor: `#FFE2E0`,
            salePriceExample: "250,00",
            description: `Empora lets you close wherever and whenever it’s convenient for you. `,
            options: [
              {
                isWrong: true,
                text: "Something goes here about option",
              },
              {
                isWrong: true,
                text: "Something goes here about option",
              },
              {
                isWrong: true,
                text: "Something goes here about option",
              },
            ],
            tableItems: [
              {
                title: "Title insurance costs",
                buyerPrice: "1,188",
                sellerPrice: "0",
                tip: "Empora lets you close wherever and whenever it’s convenient for you.",
              },
              {
                title: "Closing fees",
                buyerPrice: "300",
                sellerPrice: "0",
              },
              {
                title: "Transfer fees",
                buyerPrice: "380",
                sellerPrice: "0",
              },
            ],
          },
        ]}
      />
      <LayoutTitle>Real State Hero</LayoutTitle>
      <CustomHero
        paragraph={
          "We redesigned the title and closing process to create a digital-first experience that delivers the fastest, smoothest closings possible and simplifies the management of multiple deals."
        }
        image={staticQuery.personHero}
        title={"Empora title is built for real estate investors"}
        cardText={"Congrats on going into contract! "}
        imgFireworks={staticQuery.fireworks}
        buttonLink={{ title: "Get Started", url: "/get-started" }}
      />
      <LayoutTitle>Built For Investors</LayoutTitle>
      <BuiltForInvestors
        title={`We’re built for investors `}
        items={[
          {
            icon: staticQuery.clock,
            title: "Save serious time",
            description: `Empora lets you close wherever and whenever it’s convenient for you. We’ve eliminated meetings and done away with mountains of paperwork to cut closing time by up to 40%`,
            imageField: {
              image: staticQuery.allDeals,
              size: "wide",
            },
          },
          {
            icon: staticQuery.rocket,
            title: "Scale your business",
            description: `Empora lets you close wherever and whenever it’s convenient for you. We’ve eliminated meetings and done away with mountains of paperwork to cut closing time by up to 40%`,
            imageField: {
              image: staticQuery.appImage,
              size: "normal",
            },
          },
          {
            icon: staticQuery.note,
            title: "Unmatched deal support",
            description: `Empora lets you close wherever and whenever it’s convenient for you. We’ve eliminated meetings and done away with mountains of paperwork to cut closing time by up to 40%`,
            imageField: {
              image: staticQuery.allDeals,
              size: "wide",
            },
          },
        ]}
      />
      <LayoutTitle>TransparencyTracking</LayoutTitle>
      <TransparencyTracking
        imgChatOne={staticQuery.imgChatOne}
        txtChatTwo={"Thanks I see it now!"}
        imgHand={staticQuery.hand}
        imgChatTwo={staticQuery.imgChatTwo}
        title={"100% transparency and 24/7 tracking"}
        backgroundImg={staticQuery.house}
        txtChatOne={"We’re ready to get you scheduled for signing"}
        paragraph={
          "Track the status of your deals with our Deal Dashboard. We put everything within reach, including built-in chat, so you never have to make a phone call to ask about the status of a deal or wonder what’s next in the process."
        }
      />
      <LayoutTitle>Next To Footer</LayoutTitle>
      <NextToFooter
        image={staticQuery.hero}
        title={`Real estate investor friendly title`}
        description={`At Empora we put real estate investors first. That means that we’re constantly striving 
          to design new features and functionality that improve the title and closing process for real estate investors.`}
      />
      <LayoutTitle>Hero Our Services</LayoutTitle>
      <CustomHero
        btnText={"Request a Demo"}
        paragraph={
          "Empora Title is a state-of-the-art title agency built for real estate investors. Our digital platform brings unparalleled communication, efficiency, and visibility to the closing process, empowering real estate investors to close faster, seamlessly manage multiple deals, and save up to 30% on closing costs."
        }
        image={staticQuery.phones}
        titleTwo={
          "Title & closing services optimized for real estate investors"
        }
        dark={true}
      />
      <LayoutTitle>WhatSetsApart</LayoutTitle>
      <WhatSetsApart
        title={`What sets Empora apart`}
        description={`Empora is ideal for investors looking to scale their businesses. Our technology streamlines, simplifies, and accelerates the title and closing process—all while cutting closing costs by up to 30%. `}
        bgImage={staticQuery.bgHouse}
        items={[
          {
            topTitle: "MANAGE DEALS",
            icon: staticQuery.title1,
            title: "Easily manage multiple deals at once",
            description:
              "Empora brings all your deals together in one user-friendly location—your Deal Portal. This tool is especially powerful for real estate investors managing many deals simultaneously.",
            img: staticQuery.allDeals,
            imgMinWidth: "1000px",
          },
          {
            topTitle: "STAY IN THE LOOP",
            icon: staticQuery.title2,
            title: "Stay in the loop at every stage",
            description:
              "With our Deal Tracker, you don’t have to call to check on the status of a deal. The details and current status are always available at a glance.",
            img: staticQuery.dealTracker2,
            flipCols: true,
            imgMaxWidth: "630px",
          },
          {
            topTitle: "SIMPLE ORGANIZATION",
            icon: staticQuery.title3,
            title: "Simple organization for every deal",
            description:
              "We’ve made it easier than ever to manage the details for each deal with Deal Home. You’ll be able to track documents, see what’s next, and communicate with the account manager for each deal. Say goodbye to digging through your email to manage your deals.",
            img: staticQuery.dealInfo2,
          },
        ]}
      />
      <LayoutTitle>BackedBy</LayoutTitle>
      <BackedBy
        title={`Partnered with industry experts`}
        logos={[
          {
            img: {
              sourceUrl:
                "https://admin-empora.aftershock.agency/wp-content/uploads/2022/07/SciFiVC_logo2.png",
            },
          },
          {
            img: {
              sourceUrl:
                "https://admin-empora.aftershock.agency/wp-content/uploads/2022/07/Root_logo2.png",
            },
          },
          {
            img: {
              sourceUrl:
                "https://admin-empora.aftershock.agency/wp-content/uploads/2022/07/Capital_logo2.png",
            },
          },
        ]}
      />
      <LayoutTitle>SecondaryHero</LayoutTitle>
      <SecondaryHero
        cardText={"Estimate closing costs"}
        imageHero={staticQuery.calculatorHero}
        paragraph={
          "By automating manual processes associated with the title process, Empora is able to remove many of the fees normally associated with closing for both buyers and sellers. Use our free Closing Cost Calculator to get an estimate of how much Empora Title can save you on title, closing and transfer fees."
        }
        title={"Save up to 30% on real estate closing costs & fees"}
      />
      <LayoutTitle>CostsAndFees</LayoutTitle>
      <CostsAndFees title={`Calculate your closing costs and fees`} />
      <LayoutTitle>SubmitDeal</LayoutTitle>
      <SubmitDeal
        title={`Submit your deal`}
        description={`Submit a deal to experience Empora’s reduced closing costs and streamlined closing process for yourself.`}
        image={staticQuery.submitDeal}
      />
      <LayoutTitle>AboutCalculator</LayoutTitle>
      <AboutCalculator
        title={`About Empora’s home closing cost calculator`}
        description={`Use Empora’s free closing costs calculator to estimate & compare your 
          savings on real estate closing expenses including title fees, closing fees & transfer taxes.
           This calculator will provide an estimate of the costs you'll pay at closing with Empora
            Title versus other title companies and show you the hidden fees home closing.`}
        topTitle={`Closing Cost Calculator`}
        icon={staticQuery.businessIcon}
      />
      <LayoutTitle>Get Started Hero</LayoutTitle>
      <SecondaryHero
        cardText={"Premier title unlocked"}
        imageHero={staticQuery.getStartedHero}
        paragraph={
          "Unlock the premier title and closing solution for real estate investors. Create a free account and submit your next deal!"
        }
        title={`Have a Real Estate Deal?<br>Get started!`}
        // firstButton={{ title: "Create An Account", url: "/demo-request" }}
        // secondButton={{ title: "Schedule a Demo", url: "/demo-request" }}
        invert={true}
      />
      <LayoutTitle>HowItWorks</LayoutTitle>
      <HowItWorks
        title={`Learn to submit your first deal`}
        description={`In 2 minutes we’ll show you how to set up your account, add team members, and submit your first deal.`}
        topTitle={`PRODUCT TOUR`}
        topIcon={staticQuery.productIcon}
        videoImage={staticQuery.allDeals}
        videoId="dQw4w9WgXcQ"
        items={[
          {
            number: "01",
            icon: staticQuery.clock,
            title: "Create Your Account",
            description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
          Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.`,
          },
          {
            number: "02",
            icon: staticQuery.clock,
            title: "Enter Deal Info",
            description: `Enter deal info like property address, sales price and buyer information. 
          Lorem Ipsum is simply dummy text of the printing and typesetting industry.`,
          },
          {
            number: "03",
            icon: staticQuery.clock,
            title: "Upload Your Contract",
            description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
          Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`,
          },
          {
            number: "04",
            icon: staticQuery.clock,
            title: "Initiates The Title Search",
            description: `Within 1 business hour an Empora client success manager reviews your 
          deal and initiates the title search.`,
          },
        ]}
      />
      <LayoutTitle>InvestorsSlider</LayoutTitle>
      <InvestorsSlider
        title={`Serious real estate investors choose Empora`}
        slides={[
          {
            image: staticQuery.hero,
            name: "Michael Mahanay",
            occupation: "VP and CMO at CTL",
            paragraph: `"I love that Empora lets me manage everything in one place. 
            It's super helpful to be able to listen to voice samples, upload our own lists, 
            and find quality people that can grow with our team."`,
          },
          {
            image: staticQuery.hero,
            name: "Michael Mahanay",
            occupation: "VP and CMO at CTL",
            paragraph: `"I love that Empora lets me manage everything in one place. 
            It's super helpful to be able to listen to voice samples, upload our own lists, 
            and find quality people that can grow with our team."`,
          },
          {
            image: staticQuery.hero,
            name: "Michael Mahanay",
            occupation: "VP and CMO at CTL",
            paragraph: `"I love that Empora lets me manage everything in one place. 
            It's super helpful to be able to listen to voice samples, upload our own lists, 
            and find quality people that can grow with our team."`,
          },
          {
            image: staticQuery.hero,
            name: "Michael Mahanay",
            occupation: "VP and CMO at CTL",
            paragraph: `"I love that Empora lets me manage everything in one place. 
            It's super helpful to be able to listen to voice samples, upload our own lists, 
            and find quality people that can grow with our team."`,
          },
        ]}
      />
      <LayoutTitle>ExperiencesGrid</LayoutTitle>
      <ExperiencesGrid
        title={`A superior closing experience awaits`}
        experiences={[
          {
            icon: staticQuery.clock,
            title: `Built for Investors`,
            description: `We’ve redesigned the title and closing process for real estate investors. Every detail of Empora is designed to streamline your closing process.`,
          },
          {
            icon: staticQuery.clock,
            title: `Communication & Transparency`,
            description: `At Empora you’ll always have a direct line to one of our title experts. Enjoy proactive, seamless communication from beginning to end on your deal.`,
          },
          {
            icon: staticQuery.clock,
            title: `Time saving`,
            description: `We’ve automated much of the process - ensuring that your deals close as quickly and efficiently as possible - minimizing the effort required by you.`,
          },
          {
            icon: staticQuery.clock,
            title: `Save on closing costs`,
            description: `We save you $750+ in closing costs on every deal by eliminating the frivolous fees charged by typical title companies.`,
          },
          {
            icon: staticQuery.clock,
            title: `Stay up to date `,
            description: `Track your deal start to finish from your online portal where every detail of your deal is always up-to-date and accessible.`,
          },
          {
            icon: staticQuery.clock,
            title: `Close how you want`,
            description: `We save you time and hassle by offering digital closings, remote online notarization, and traveling notary services.`,
          },
        ]}
      />
      <LayoutTitle>WhyWorkWith</LayoutTitle>
      <NextToFooter
        image={staticQuery.hero}
        title={`Why work with Empora?`}
        description={`Empora Title is a premier tech-forward digital title company that delivers visibility, transparency, speed and accuracy with every transaction. Our team of accessible experts provide a swift, holistic experience with proactive, informative, and insightful communications to all real estate investors.`}
        hideLogo
        txtSecondBtn={`Create an Account`}
      />
      <LayoutTitle>Demo Hero</LayoutTitle>
      <DemoHero
        title={"Learn how Empora Title can elevate your title and closing"}
        paragraph={
          "Schedule a 15-minute demo and learn why Empora is the premier title partner for serious real estate investors. Empora will help you:"
        }
        formText={
          "Empora Title needs your contact information to schedule your demo. We may also contact you about our products and services. You may unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, please review our Privacy Policy."
        }
        listFeatures={[
          {
            feature: "Save $750+ in closing costs on every deal",
          },
          {
            feature: "Streamline your closing process",
          },
          {
            feature: "Easily manage multiple deals",
          },
          {
            feature: "Close faster",
          },
        ]}
      /> 
      <LayoutTitle>Short Demo Hero</LayoutTitle>
      <ShortDemoHero
        title={"Learn how Empora Title can elevate your title and closing"}
        paragraph={
          "Schedule a 15-minute demo and learn why Empora is the premier title partner for serious real estate investors. Empora will help you:"
        }
        formText={
          "Empora Title needs your contact information to schedule your demo. We may also contact you about our products and services. You may unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, please review our Privacy Policy."
        }
        listFeatures={[
          {
            feature: "Save $750+ in closing costs on every deal",
          },
          {
            feature: "Streamline your closing process",
          },
          {
            feature: "Easily manage multiple deals",
          },
          {
            feature: "Close faster",
          },
        ]}
      /> 
      <LayoutTitle>TrustedBy Slider</LayoutTitle>
      <TrustedBySlider
        bgImage={staticQuery.trustedByBG}
        title={`Trusted by Real Estate Investors`}
        items={[
          {
            name: "Ciera L.",
            title: "VP and CMO at CTL",
            paragraph: `”Empora always takes care of me. Their technology makes my title experience easy, but I always know I can reach out and get personalized service when it’s needed.”`,
          },
          {
            name: "Sydney G.",
            title: "VP and CMO at CTL",
            paragraph: `“The communication from the escrow officer, Heather, was great. The online portal makes for a great, easy closing…We will be using Empora exclusively for Ohio from now on.”`,
          },
          {
            name: "Ciera L.",
            title: "VP and CMO at CTL",
            paragraph: `”Empora always takes care of me. Their technology makes my title experience easy, but I always know I can reach out and get personalized service when it’s needed.”`,
          },
          {
            name: "Sydney G.",
            title: "VP and CMO at CTL",
            paragraph: `“The communication from the escrow officer, Heather, was great. The online portal makes for a great, easy closing…We will be using Empora exclusively for Ohio from now on.”`,
          },
        ]}
        logos={[
          {
            logo: staticQuery.googleLogo,
            rating: 3.6,
          },
          {
            logo: staticQuery.facebookLogo,
            rating: 5,
          },
        ]}
      />
      <LayoutTitle>FAQ Hero</LayoutTitle>
      <FaqHero
        title={"Frequently asked questions"}
        text={
          "Everything you need to know about Empora and the service we provide. Can't find the answer you're looking for? Please give us a call at (614) 660-5503 or <a>email us.</a>"
        }
      />
      <LayoutTitle>FAQ Content</LayoutTitle>
      <FaqContent faq={Faq} />
      <LayoutTitle>CardsOurServices</LayoutTitle>
      <CardsOurServices
        title={"End-to-end title and closing services"}
        cards={[
          {
            title: "Core title <br/>services",
            features: [
              {
                feature: "Title & Escrow for real estate closings",
              },
              {
                feature:
                  "Title searches, including lien searches and property information reports",
              },
              { feature: "Title clearing" },
              { feature: "Lender coordination" },
              {
                feature:
                  "Closing coordination - we’ll meet you any time, anywhere",
              },
              {
                feature: "Recording services",
              },
            ],
          },
          {
            title: "Optimized for investors",
            features: [
              {
                feature:
                  "Investment transactions for residential and commercial properties",
              },
              { feature: "Digital transaction management" },
              { feature: "Digital earnest money submission" },
              { feature: "Construction escrow" },
              {
                feature: "Remote online notarizations",
              },
              {
                feature: "Coordinating with investor financers",
              },
              {
                feature: "Record keeping",
              },
            ],
          },
        ]}
        buttonLink={{
          title: "Schedule a Demo to Learn more",
          url: "/demo-request",
        }}
      />
      <LayoutTitle>PostProperty</LayoutTitle>
      <PostProperty
        title="Post your property"
        description="Submit your property to Empora and we’ll feature it in our marketplace newsletter that features available properties in your area."
      />

      <LayoutTitle>TestimonialBanner</LayoutTitle>
      <TestimonialBanner
        title="Best in class seller experience"
        description="I had the pleasure of working with Empora on the sale of my house. The buyer recommended Empora, and I was pleased with how fast and smooth the process was. Empora made it easy - including meeting my wife and me at our convenience for an in-person digital closing."
        authorName="Rick Wilkinson"
        authorTitle="Seller"
        button={{ title: "See more Reviews", url: "/" }}
        authorImage={staticQuery.authorImage}
      />
    </Layout>
  )
}

export default Layouts
